import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect:'register',
  },
  {
    name: "register",
    path: "/register/:mobile?",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },{
    name: "downloadApp",
    path: "/downloadApp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setups.vue"),
  },{
    name: "protocol",
    path: "/protocol/:title?",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  // protocol
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
